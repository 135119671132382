import React, { useState } from "react"
import "./styles/faq.scss"

const listItems = [
  {
    id: 0,
    title: "What is hyperacidity?",
    body: "Hyperacidity is a condition where there is excessive acid production in the stomach, leading to symptoms such as heartburn, acid reflux, and stomach pain.",
  },
  {
    id: 1,
    title: "What are the symptoms of hyperacidity?",
    body: "The common symptoms of hyperacidity include heartburn, acid reflux, bloating, stomach pain, and nausea.",
  },
  {
    id: 3,
    title: "How can I prevent hyperacidity?",
    body: "You can prevent hyperacidity by avoiding triggering foods, eating smaller and more frequent meals, reducing stress, quitting smoking, and avoiding alcohol.",
  },
  {
    id: 4,
    title: "What foods should I avoid if I have hyperacidity?",
    body: "Foods to avoid if you have hyperacidity include spicy and acidic foods, caffeine, chocolate, and alcohol.",
  },
  {
    id: 5,
    title: "What foods can I eat if I have hyperacidity?",
    body: "Foods that are low in acid and fat can be eaten if you have hyperacidity, including fruits, vegetables, lean proteins, and whole grains.",
  },
  {
    id: 6,
    title: "What are the best home remedies for hyperacidity?",
    body: "Home remedies for hyperacidity include drinking plenty of water, eating slowly, avoiding tight clothing, and chewing gum to stimulate saliva production.",
  },
  {
    id: 7,
    title:"Can stress cause hyperacidity?",
    body: "Yes, stress can cause hyperacidity as it can trigger the production of stomach acid.",
  },
  {
    id: 8,
    title:"How is hyperacidity diagnosed?",
    body: "Hyperacidity is typically diagnosed based on symptoms and a physical exam. In some cases, tests such as an endoscopy or pH monitoring may be performed.",
  },
  {
    id: 9,
    title: "What medications can be used to treat hyperacidity?",
    body: "Medications such as antacids, H2 blockers, and proton pump inhibitors can be used to treat hyperacidity.",
  },
  {
    id: 10,
    title:"How long does it take to treat hyperacidity?",
    body: "The length of time it takes to treat hyperacidity can vary depending on the severity of the condition and the individual's response to treatment.",
  },
  {
    id: 11,
    title:"Can hyperacidity lead to more serious health problems?",
    body: "Untreated hyperacidity can lead to more serious health problems such as ulcers, esophagitis, and even esophageal cancer.",
  },
  {
    id: 12,
    title:"What lifestyle changes can I make to manage hyperacidity?",
    body: "Lifestyle changes such as eating smaller meals, avoiding trigger foods, and reducing stress can help manage hyperacidity.",
  },
  {
    id: 13,
    title:"Is it safe to drink alcohol if I have hyperacidity?",
    body: "It is best to avoid alcohol if you have hyperacidity as it can increase stomach acid production and worsen symptoms.",
  },
  {
    id: 14,
    title:"Can hyperacidity be cured completely?",
    body: "Hyperacidity can be managed and treated effectively with a combination of medication, lifestyle changes, and in some cases, surgery. However, it may not be completely cured in some individuals.",
  },
  
]
export default function Faq() {
  const [active, setActive] = useState(null)
  return (
    <div className="faq">
      <section className="list">
        <div className="faqheader">
          <h1 className="heading">Frequent Questions our clients ask about Hyperacidity</h1>
        </div>
        {listItems.map(item => (
          <button
            key={item.id}
            className="list-item"
            open={active === item.id || false}
            onClick={e => {
              e.preventDefault()
              if (active === item.id) {
                setActive(null)
              } else {
                setActive(item.id)
              }
            }}
          >
            <header className="list-item-title">
              <p>{item.title}</p>
              <i className="list-item-plus-icon" />
            </header>
            <div className="list-item-body">
              <article className="list-item-article">{item.body}</article>
            </div>
          </button>
        ))}
      </section>
    </div>
  )
}
