import React from "react"
import styled from "styled-components"
import Seo from "../components/seo"
import Faq from "../sections/faq/HyperacidityFaq"
import Footer from "../components/homeNav/footer/Footer"
import BotNav from "../components/bottomNav"
import PageNav from "../components/homeNav/PageNav"


const Hyperacidity = () => {
    return (
        <>
            <Seo
                title="Hyperacidity FAQs | Afyabook"
                description="Frequently asked Questions on Hyperacidity"
                img="https://res.cloudinary.com/tripleaim-software/image/upload/v1679675006/blog/stomachache_lux3oe.jpg"
                keywords="Hyperacidity,Symptoms of Hyperacidity, Hyperacidity treatment,Hyperacidity diet,Hyperacidity prevention,Hyperacidity medications"
                siteUrl="https://www.afyabook.com/hyperacidityFaq"
                canonical="https://www.afyabook.com/hyperacidityFaq"
            ></Seo>
            <PageNav />
            <HeaderContainer>
                <HeroPortfolio>
                    <HeroHeader>Hyperacidity FAQs</HeroHeader>
                </HeroPortfolio>
            </HeaderContainer>
            <script type="application/ld+json">
                      {`{
                        "@context": "https://schema.org/",
                        "@type": "FAQPage",
                        "mainEntity": [
                            {
                              "@type": "Question",
                              "name": "What is hyperacidity?",
                              "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "Hyperacidity is a condition where there is excessive acid production in the stomach, leading to symptoms such as heartburn, acid reflux, and stomach pain."
                              }
                            },
                            {
                              "@type": "Question",
                              "name": "What are the symptoms of hyperacidity?",
                              "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "The common symptoms of hyperacidity include heartburn, acid reflux, bloating, stomach pain, and nausea."
                              }
                            },
                            {
                              "@type": "Question",
                              "name": "How can I prevent hyperacidity?",
                              "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "You can prevent hyperacidity by avoiding triggering foods, eating smaller and more frequent meals, reducing stress, quitting smoking, and avoiding alcohol."
                              }
                            },
                            {
                              "@type": "Question",
                              "name": "What foods should I avoid if I have hyperacidity?",
                              "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "Foods to avoid if you have hyperacidity include spicy and acidic foods, caffeine, chocolate, and alcohol."
                              }
                            },
                            {
                              "@type": "Question",
                              "name": "What foods can I eat if I have hyperacidity?",
                              "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "Foods that are low in acid and fat can be eaten if you have hyperacidity, including fruits, vegetables, lean proteins, and whole grains."
                              }
                            },
                            {
                              "@type": "Question",
                              "name": "What are the best home remedies for hyperacidity?",
                              "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "Home remedies for hyperacidity include drinking plenty of water, eating slowly, avoiding tight clothing, and chewing gum to stimulate saliva production."
                              }
                            },
                            {
                              "@type": "Question",
                              "name": "Can stress cause hyperacidity?",
                              "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "Yes, stress can cause hyperacidity as it can trigger the production of stomach acid."
                              }
                            },
                            {
                              "@type": "Question",
                              "name": "How is hyperacidity diagnosed?",
                              "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "Hyperacidity is typically diagnosed based on symptoms and a physical exam. In some cases, tests such as an endoscopy or pH monitoring may be performed."
                              }
                            },
                            {
                              "@type": "Question",
                              "name": "What medications can be used to treat hyperacidity?",
                              "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "Medications such as antacids, H2 blockers, and proton pump inhibitors can be used to treat hyperacidity."
                              }
                            },
                            {
                              "@type": "Question",
                              "name": "How long does it take to treat hyperacidity?",
                              "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "The length of time it takes to treat hyperacidity can vary depending on the severity of the condition and the individual's response to treatment."
                              }
                            },
                            {
                              "@type": "Question",
                              "name": "Can hyperacidity lead to more serious health problems?",
                              "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "Untreated hyperacidity can lead to more serious health problems such as ulcers, esophagitis, and even esophageal cancer."
                              }
                            },
                            {
                              "@type": "Question",
                              "name": "What lifestyle changes can I make to manage hyperacidity?",
                              "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "Lifestyle changes such as eating smaller meals, avoiding trigger foods, and reducing stress can help manage hyperacidity."
                              }
                            },
                            {
                              "@type": "Question",
                              "name": "Is it safe to drink alcohol if I have hyperacidity?",
                              "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "It is best to avoid alcohol if you have hyperacidity as it can increase stomach acid production and worsen symptoms."
                              }
                            },
                            {
                              "@type": "Question",
                              "name": "Can hyperacidity be cured completely?",
                              "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "Hyperacidity can be managed and treated effectively with a combination of medication, lifestyle changes, and in some cases, surgery. However, it may not be completely cured in some individuals."
                              }
                            }
                          ]
                      }`}
                    </script>
            <Faq />
            <BotNav />
            <Footer />
        </>
    )
}

export default Hyperacidity

const HeaderContainer = styled.div`
    margin-top: 70px;
    width:100%;
`
const HeroPortfolio = styled.div`
    background: linear-gradient(to bottom, #1C333A 10%,#1B353C 50%,#1D3238 70%, #187E9C 100%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #fff;
    align-items: center;
    height: 50vh;
    padding: 0 1rem;
    
`
const HeroHeader = styled.h1`
    font-size: clamp(1.5rem,6vw, 3rem);
`

